

<template>
    <div :class="slideCssClass + (computedOptions.flex ? ' flex':'')">
        <div class="vertical-middle fixed-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <img :src="logoURL" :alt="getLocalizedText(labels.altImage)">
                        <h2 v-if="labels.h2" class="ls0 fw-bold text-uppercase" style="font-size: 48px;" data-animate="fadeInUp">{{ $cms.utils.getComputedLabel(labels.h2) }}</h2>
                        <h3 v-if="labels.h3" class="ls0 fw-bold text-uppercase" style="font-size: 32px;" data-animate="fadeInUp">{{ $cms.utils.getComputedLabel(labels.h3) }}</h3>
                        <h4 v-if="labels.h4" class="ls0 fw-bold text-uppercase" style="font-size: 20px;" data-animate="fadeInUp">{{ $cms.utils.getComputedLabel(labels.h4) }}</h4>
                        <div v-if="buttons.length > 0">
                            <CmsComponent v-for="(component, index) in buttons" :key="index+'_buttons'" :component="component"></CmsComponent>
                        </div>
                        <!-- <a href="#presentation" @click="onAnchorLinkClick($event, '#presentation')" class="" data-animate="fadeInUp" data-delay="400" data-scrollto="#team" data-offset="60">En savoir plus <i class="icon-line-arrow-down"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

.main-slide img {
    width:100px;
    margin-left:auto;
    margin-right:auto;
    display:block;
}

.main-slide h2 {
    margin-top:20px;
}

.main-slide h2, .main-slide h3, .main-slide h4 {
    margin-bottom:0px;
    text-align: center;
}

#slider {
    height:350px;
    min-height: 350px
}

#slider .swiper-slide {
        justify-content: center;
    display: flex;
    flex-direction: column;
}


</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount } from '@fwk-node-modules/vue'
import { CmsLabel } from '@fwk-client/modules/cms/types';
import { useCmsModule } from '@fwk-client/utils/vue-3-migration';
import { Component } from '@fwk-client/modules/cms/helpers/components';

/*
slides = [
        {
            thumb: "images/slider/boxed/thumbs/2.jpg",
            link: undefined,
            image: "images/slider/boxed/2.jpg",
            labelPosition: " justify-content-start align-items-end",
            labelStyle: " bg-light text-dark ms-3 mb-3",
            altImageLabel: {
                "fr" : "Label de l'image"
            },
            label: {
                "fr" : "Le label du slide"
            }
        }
    ]
*/


/** @cmsOptions */
export interface MainSlideOptions {
    logoURL:string
}

/** @cmsLabels */
export interface MainSlideLabels {
    /** @cmsType CmsLabel */
    altImage: CmsLabel;
    /** @cmsType CmsLabel */
    h2?: CmsLabel;
    /** @cmsType CmsLabel */
    h3?: CmsLabel;
    /** @cmsType CmsLabel */
    h4?: CmsLabel;
}

/** @cmsSlots */
export interface MainSliderSlots {
    buttons?:Component[]
}

export default defineComponent({
    props: {
        options: Object as PropType<MainSlideOptions>,
        labels: {
          type: Object as PropType<MainSlideLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<MainSliderSlots>,
        height: {
            type: [Number,String],
            required: false
        }
    },
    setup(props, context) {
        const cms = useCmsModule(); 
        
        const computedOptions:MainSlideOptions = {
            logoURL:"",
            ...props.options
        };

        const slideCssClass = computed(() => {
            var cssClass = "swiper-slide dark white main-slide";
            if(props.height != undefined && typeof props.height == "string" && props.height == "auto") {
                cssClass += " h-auto";
            }
            else if(props.height != undefined && props.height > 0) {
                cssClass += " min-vh-"+props.height;
            }
            return cssClass;
        })

        onMounted(() => {
        })

        onBeforeUnmount(() => {
        })

        return {
            slideCssClass,
            labels:props.labels,
            logoURL:cms.utils.getComputedPictureURL(computedOptions.logoURL),
            buttons:props.components ? props.components.buttons : [],
            computedOptions: {
                ...computedOptions
            }
        }

    }
})
</script>